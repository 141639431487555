import { render, staticRenderFns } from "./farmParcel.vue?vue&type=template&id=4c4b4506&scoped=true&"
import script from "./farmParcel.vue?vue&type=script&lang=js&"
export * from "./farmParcel.vue?vue&type=script&lang=js&"
import style0 from "./farmParcel.vue?vue&type=style&index=0&id=4c4b4506&scoped=true&lang=css&"
import style1 from "./farmParcel.vue?vue&type=style&index=1&id=4c4b4506&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c4b4506",
  null
  
)

export default component.exports