<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>地块信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'farmParcel-add'">
          <el-button type="primary" size="small" @click="addFarmParcel">新增地块</el-button
          >
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
              :data="data"
              row-key="id"
            >
              <el-table-column prop="name" label="地块名称"> </el-table-column>
              <el-table-column prop="farmAreaName" label="所属基地">
              </el-table-column>
              <el-table-column prop="plantCrop" label="当前种植农作物">
              </el-table-column>
              <el-table-column prop="hasSet" label="地块区域" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.hasSet ? "已配置" : "未配置" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="hasSet" label="坐标点" min-width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.hasSet
                      ? `${scope.row.lng} , ${scope.row.lat}`
                      : "未配置"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                min-width="100"
                label="操作"
                align="center"
                v-if="authVerify.verify('farmParcel-edit,farmParcel-delete')"
              >
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-edit-outline"
                    size="mini"
                    @click="editFarmParcel(scope.row)"
                    v-auth="'farmParcel-edit'"
                  >
                    编辑
                  </el-button>
                  <el-button
                    icon="el-icon-delete"
                    size="mini"
                    @click="deleteFarmParcel(scope.row)"
                    v-auth="'farmParcel-delete'"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-card>
    <!-- 添加 编辑 -->
    <a-drawer
      :title="addOrEdit == 'add' ? '新增地块' : '编辑地块'"
      width="400"
      placement="right"
      :closable="false"
      :visible="addFarmParcelShow"
      @close="closeDrawer"
    >
      <div style="margin-bottom: 3.5rem">
        <a-form-model
          :model="currentData"
          ref="farmParcelRefForm"
          :rules="formRules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-form-model-item label="地块名称" prop="name">
            <a-input v-model="currentData.name" placeholder="请输入地块名称" />
          </a-form-model-item>
          <a-form-model-item label="所属基地" prop="farmAreaId">
            <a-select
              v-model="currentData.farmAreaId"
              placeholder="请选择所属基地"
            >
              <a-select-option
                v-for="obj in farmAreaList"
                :key="obj.id"
                :value="obj.id"
              >
                {{ obj.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="当前种植农作物" prop="plantCrop">
            <a-input v-model="currentData.plantCrop" placeholder="请输入当前种植农作物" />
          </a-form-model-item>
          <a-form-model-item label="地块区域">
            <div class="pick-area">
              <a-button type="primary" @click="pickMap">
                {{ currentData.centerPoint ? "修改区域" : "配置区域" }}
              </a-button>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addFarmParcelShow = false"
          >取消</a-button
        >
        <a-button type="primary" @click="handleConfirm">确认</a-button>
      </div>
    </a-drawer>
    <!-- 地图选择 -->
    <mapTemp v-if="showPickMap" :current="currentData" @cancelMap="pickMap" @handleSuc="handleUpdateFarmParcel"/>
  </div>
</template>

<script>
import mapTemp from '../map/mapByParcel'
export default {
  components:{
    mapTemp
  },
  data() {
    return {
      currentData: {
        centerPoint: "",
        name: "",
          plantCrop: "",
        farmAreaId: [],
          points: undefined,
          lat: undefined,
          lat: undefined,
      },
      farmAreaFormValue: {
        id: "",
        name: "",
        fullName: "",
        region: "",
        contact: "",
        address: "",
        acreage: "",
        plantAcreage: "",
        elevation: "",
        rent: "",
        terrainList: [],
        plantingTimeList: [],
        irrigationMethodList: [],
        waterConditionsList: [],
        soilConditionsList: [],
        terrain: "",
        plantingTime: "",
        irrigationMethod: "",
        waterConditions: "",
        soilConditions: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入地块名称!", trigger: "change" }],
        farmAreaId: [
          { required: true, message: "请选择所属基地!", trigger: "change" },
        ],
      },
      addOrEdit: 'add',
      farmAreaList: [],
      loading: true,
      addFarmParcelShow: false,
      data: [],
      showPickMap: false
    };
  },
  watch: {},
  mounted() {},
  created() {
    // 获取所属基地列表
    this.getFarmAreaList();
    // 获取列表数据
    this.getFarmParcelData();
  },
  filters: {},
  computed: {},
  methods: {
    getFarmAreaList() {
      this.http.farmArea.getFarmAreaList().then((response) => {
        if (response.data.status == 200) {
          this.farmAreaList = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getFarmParcelData() {
      this.loading = true;
      this.http.farmParcel.getFarmParcelList().then((res) => {
        if (res.data.status == 200) {
          let list = res.data.data;
          list.forEach((val) => {
            val.hasSet = false;
          });
          this.data = list;
          this.handlePoints();
          this.loading = false;
        } else {
          this.$message.error("获取地块信息失败。");
        }
      });
    },
    // 处理列表坐标
    handlePoints() {
      let list = this.data;
      list.forEach((val) => {
        if (val.points) {
          val.hasSet = "已配置";
          val.centerPoint = this.getPointsCenter(
            JSON.parse(val.points)[0].join(";").split(";")
          );
        }
      });
      this.data = list;
    },
    //返回中心点的数组[纬度,精度]
    getPointsCenter(points) {
      var point_num = points.length; //坐标点个数
      var X = 0,
        Y = 0,
        Z = 0;
      for (let i = 0; i < points.length; i++) {
        if (points[i] == "") {
          continue;
        }
        let point = points[i].split(",");
        var lat, lng, x, y, z;
        lat = (parseFloat(point[1]) * Math.PI) / 180;
        lng = (parseFloat(point[0]) * Math.PI) / 180;
        x = Math.cos(lat) * Math.cos(lng);
        y = Math.cos(lat) * Math.sin(lng);
        z = Math.sin(lat);
        X += x;
        Y += y;
        Z += z;
      }
      X = X / point_num;
      Y = Y / point_num;
      Z = Z / point_num;

      var tmp_lng = Math.atan2(Y, X);
      var tmp_lat = Math.atan2(Z, Math.sqrt(X * X + Y * Y));

      return [(tmp_lat * 180) / Math.PI, (tmp_lng * 180) / Math.PI];
    },
    deleteFarmParcel(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.farmParcel.deleteFarmParcel({ id: key.id }).then((res) => {
            if (res.data.status == 200) {
              this.$message.success(res.data.msg);
              self.getFarmParcelData();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        onCancel() {},
      });
    },

    handleConfirm(){
      if(this.addOrEdit == 'add'){
        this.toAddFarmParcel()
      }
      if(this.addOrEdit == 'edit'){
        this.toEditFarmParcel()
      }
    },
    addFarmParcel() {
      let that = this
      that.addOrEdit = 'add';
      that.currentData = {
        centerPoint: "",
        name: "",
          plantCrop: "",
        farmAreaId: [],
          points: undefined,
          lat: undefined,
          lat: undefined,
      };
      that.addFarmParcelShow = true
      // that.$nextTick(()=>{
      //   that.$refs.farmParcelRefForm.resetFields();
      // })
    },
    closeDrawer(){
      this.$refs.farmParcelRefForm.resetFields();
      this.addFarmParcelShow = false
    },
    editFarmParcel(row) {
      let that = this
      that.addOrEdit = 'edit';
        let data = {
            id:row.id,
            centerPoint: row.centerPoint,
            name: row.name,
            plantCrop: row.plantCrop,
            farmAreaId: row.farmAreaId,
            points:row.points,
            lat:row.lat,
            lng:row.lng,
        };
        that.currentData = data;
      that.addFarmParcelShow = true
      // setTimeout(() => {
      //   that.$nextTick(()=>{
      //     that.$refs.farmParcelRefForm.resetFields();
      //   })
      // }, 500);
    },

    toAddFarmParcel() {
      let data = this.currentData;
      // 判断空字段
        this.$refs.farmParcelRefForm.validate(valid => {
            if (valid) {
                // 添加地块方法
                this.http.farmParcel.createFarmParcel(data).then((res) => {
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.getFarmParcelData();
                        this.addFarmParcelShow = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        });

    },
    toEditFarmParcel() {
      let data = this.currentData;
      // 判断空字段
        this.$refs.farmParcelRefForm.validate(valid => {
            if (valid) {
                // 添加地块方法
                this.http.farmParcel.updateFarmParcel(data).then((res) => {
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.getFarmParcelData();
                        this.addFarmParcelShow = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        });

    },
    // initCurrentData() {
    //   this.currentData = {
    //     centerPoint: "",
    //     name: "",
    //     farmAreaId: [],
    //   };
    // },
    pickMap(){
      this.showPickMap = !this.showPickMap
    },
    handleUpdateFarmParcel(pdata){
      let that = this
      this.$message.success("操作成功");
      // this.addFarmParcelShow = false
      that.currentData.points = pdata.points
      that.currentData.lat = pdata.lat
      that.currentData.lng = pdata.lng
      that.currentData.centerPoint = [pdata.lng,pdata.lat]
      that.showPickMap = !that.showPickMap
        that.getFarmParcelData();
    }
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
