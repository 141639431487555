<template>
  <div class="map" tabindex="-1">
    <div class="map-box">
      <div class="head">
        <div class="left">
          <span>{{ current.name }}</span>
          <!-- <div class="item">
            <div class="label">名称：</div>
            <a-input
              v-model="parcelInfo.name"
              class="inpsty"
              placeholder="请输入地块名称"
            />
          </div> -->
        </div>
        <div class="right">
          <a-button size="small" style="margin-right: 7px" @click="delDraw"
            >重置</a-button
          >
          <a-button size="small" style="margin-right: 7px" @click="cancel"
            >取消</a-button
          >
          <a-button type="primary" size="small" @click="confirm">确定</a-button>
        </div>
      </div>
      <div class="main">
        <div class="mapDom" id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Scene, PolygonLayer, Marker } from "@antv/l7";
import { Mapbox } from "@antv/l7-maps";
import { DrawControl, DrawPolygon } from "@antv/l7-draw";
export default {
  props: {
    current: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      scene: null,
      pointList: [],
      handlePointList: [],
      center: [112.44404932562838, 22.752685262364295],
      hasPoints: false,
      parcelInfo: {
        acreage: "", //面积
        // color: '',  //地块颜色 web显示
        farmAreaId: "", //农场基地id
        lng: "", //经度
        lat: "", //纬度	query	false
        name: "", //地块名称
        points: "", //坐标点
        id: "", //id
      },
      drawControl: null,
      draw: null,
    };
  },
  mounted() {
    let cdata = this.current;
    // 有中心点的情况
    if (cdata.lat && cdata.lng) {
      this.center = [cdata.lng, cdata.lat];
    }
    // 有坐标的情况
    let pointList = !cdata.points ? [] : JSON.parse(cdata.points);
    if(pointList.length > 0){
      if (pointList[0].length > 1) {
        this.hasPoints = true;
        this.handlePointList = pointList;
      }
    }
    this.initMap();
  },
  methods: {
    initMap() {
      let that = this;
      this.scene = null;
      let scene = this.scene;
      scene = new Scene({
        id: "map",
        logoVisible: false, // 是否显示logo
        map: new Mapbox({
          style: "mapbox://styles/mapbox/satellite-streets-v11",
          pitch: 10,
          // layers: [],
          center: this.center,
          zoom: 14.95,
          rotation: 19.313180925794313,
          token:
            "pk.eyJ1IjoiZGlhbmF5IiwiYSI6ImNrdDNvazJpdTB2ejgydXVsdW50OWgyMTMifQ.Qax_apbCk2-XhMlwmzB6kA",
        }),
      });
      // scene.setMapStatus({
      //   dragEnable: false, //是否允许地图拖拽
      //   keyboardEnable: false, //是否允许形键盘事件
      //   doubleClickZoom: false, //双击放大
      //   zoomEnable: false, //滚动缩放
      //   rotateEnable: false, //旋转
      // });
      let style = {
        active: {
          point: {
            type: "PointLayer",
            shape: "circle",
            color: "#fbb03b",
            size: 5,
            style: {
              stroke: "#fff",
              strokeWidth: 2,
            },
          },
          line: {
            type: "LineLayer",
            shape: "line",
            color: "#fbb03b",
            size: 1,
            style: {
              opacity: 1,
              lineType: "dash",
              dashArray: [2, 2],
            },
          },
          polygon: {
            shape: "fill",
            color: "#fbb03b",
            style: {
              opacity: 0.1,
              stroke: "#fbb03b",
              strokeWidth: 1,
              strokeOpacity: 1,
              lineType: "dash",
              dashArray: [2, 2],
            },
          },
        },
        normal: {
          polygon: {
            type: "PolygonLayer",
            shape: "fill",
            color: "#3bb2d0",
            style: {
              opacity: 0.1,
              stroke: "#3bb2d0",
              strokeWidth: 1,
              strokeOpacity: 1,
              lineType: "solid",
              dashArray: [2, 2],
            },
          },
          line: {
            type: "LineLayer",
            shape: "line",
            size: 1,
            color: "#3bb2d0",
            style: {
              opacity: 1,
            },
          },
          point: {
            type: "PointLayer",
            shape: "circle",
            color: "#3bb2d0",
            size: 3,
            style: {
              stroke: "#fff",
              strokeWidth: 2,
            },
          },
        },
        normal_point: {
          type: "PointLayer",
          shape: "circle",
          color: "#3bb2d0",
          size: 3,
          style: {
            stroke: "#fff",
            strokeWidth: 2,
          },
        },
        mid_point: {
          point: {
            type: "PointLayer",
            shape: "circle",
            color: "#fbb03b",
            size: 3,
            style: {},
          },
        },
      };
      scene.on("loaded", () => {
        // const layer1 = new AMap.TileLayer.Satellite();
        // scene.map.setLayers([]);
        // layer1.setMap(scene.map);
        const drawControl = new DrawControl(scene, {
          position: "topright",
          layout: "horizontal", // horizontal vertical
          style,
          controls: {
            point: false,
            // polygon: {
            //   editEnable: false,
            // },
            polygon: {
              editEnable: true,
            },
            line: false,
            circle: false,
            rect: false,
            delete: true,
          },
        });
        if (that.hasPoints) {
          // console.log("绘制多边形");
          let polygon = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: that.handlePointList,
                },
              },
            ],
          };
          let draw = new DrawPolygon(scene, {
            data: polygon,
          });
          draw.on("draw.create", (e) => {
            // console.log(e);
          });
          draw.on("draw.update", (e) => {
            // console.log("update", e);
          });
          that.draw = draw
        }

        drawControl.on("draw.create", (e) => {
          that.pointList = drawControl.getAllData().polygon.features;
        });
        drawControl.on("draw.delete", (e) => {
          that.pointList = drawControl.getAllData().polygon.features;
        });
        drawControl.on("draw.update", (e) => {
          that.pointList = drawControl.getAllData().polygon.features;
        });
        scene.addControl(drawControl);
        this.drawControl = drawControl;
        this.scene = scene;
      });
    },
    confirm() {
      if(this.hasPoints){
        let sum = this.handlePointList.length + this.pointList.length
        if(sum > 1){
          this.$message.error("地块区域只能有一块");
          return;
        }
        if(this.handlePointList.length == 1){
          this.$emit("cancelMap");
          return
        }
      }
      if (this.pointList.length > 1) {
        this.$message.error("地块区域只能有一块");
        return;
      } else if (this.pointList.length < 1) {
        this.$message.error("请画出地块区域");
        return;
      }
      // if(!this.parcelInfo.name){
      //   this.$message.error('请输入地块名称')
      //   return;
      // }
      let data = this.parcelInfo;
      // 初始化坐标数组
      this.initPoints(this.pointList);
      // 计算面积
      data.acreage = this.computeSignedArea(this.handlePointList);
      // 计算中心点
      let center = this.getPointsCenter(this.handlePointList);
      data.lat = center[0];
      data.lng = center[1];
      data.points = JSON.stringify([this.handlePointList]);
      data.name = this.current.name
      data.id = this.current.id
      data.farmAreaId = this.current.farmAreaId
      console.log(data);
      // 编辑地块方法
      this.http.farmParcel.updateFarmParcel(data).then((res) => {
        if (res.status == 200) {
          this.$emit('handleSuc',data)
        } else {
          this.$message.error("操作失败，请稍后再试");
        }
      });
    },
    cancel() {
      this.$emit("cancelMap");
    },
    delDraw() {
      this.hasPoints = false
      this.handlePointList = []
      this.draw.removeAllData();
      this.drawControl.removeAllData();
      this.initMap()
    },
    // 处理坐标
    initPoints(points) {
      let plist = points[0].geometry.coordinates[0];
      this.handlePointList = plist;
    },
    computeSignedArea(path) {
      // 传入path：{
      //     [{lat:,lng:}],[{lat:,lng:}],[{lat:,lng:}]
      // }
      let newPath = [];
      path.forEach((val) => {
        newPath.push({ lat: val[0], lng: val[1] });
      });
      let radius = 6371009;
      let len = newPath.length;
      if (len < 3) return 0;
      let total = 0;
      let prev = newPath[len - 1];
      let prevTanLat = Math.tan((Math.PI / 2 - (prev.lat / 180) * Math.PI) / 2);
      let prevLng = (prev.lng / 180) * Math.PI;
      for (let i in newPath) {
        let tanLat = Math.tan(
          (Math.PI / 2 - (newPath[i].lat / 180) * Math.PI) / 2
        );
        let lng = (newPath[i].lng / 180) * Math.PI;
        total += this.polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
        prevTanLat = tanLat;
        prevLng = lng;
      }
      return Math.abs(total * (radius * radius));
    },
    polarTriangleArea(tan1, lng1, tan2, lng2) {
      let deltaLng = lng1 - lng2;
      let t = tan1 * tan2;
      return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
    },
    //返回中心点的数组[纬度,精度]
    getPointsCenter(points) {
      var point_num = points.length; //坐标点个数
      var X = 0,
        Y = 0,
        Z = 0;
      for (let i = 0; i < points.length; i++) {
        if (points[i] == "") {
          continue;
        }
        let point = points[i];
        var lat, lng, x, y, z;
        lat = (parseFloat(point[1]) * Math.PI) / 180;
        lng = (parseFloat(point[0]) * Math.PI) / 180;
        x = Math.cos(lat) * Math.cos(lng);
        y = Math.cos(lat) * Math.sin(lng);
        z = Math.sin(lat);
        X += x;
        Y += y;
        Z += z;
      }
      X = X / point_num;
      Y = Y / point_num;
      Z = Z / point_num;

      var tmp_lng = Math.atan2(Y, X);
      var tmp_lat = Math.atan2(Z, Math.sqrt(X * X + Y * Y));

      return [(tmp_lat * 180) / Math.PI, (tmp_lng * 180) / Math.PI];
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.map {
  width: 100%;
  height: 100%;
  z-index: 1001;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .map-box {
    width: 900px;
    height: 600px;
    background: #fff;
    padding: 0 14px 14px 14px;
    border-radius: 8px;
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    .left {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        .label {
          margin-right: 7px;
        }
        .inpsty {
          width: 200px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 48px);
    .mapDom {
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      background: #333;
    }
  }
}
</style>
